"use client";

import { MARoutes } from "@/app/[lang]/(memberzone)/routes";
import clsx from "@/utils/clsx";
import {
  useLoyaltyLionCustomer,
  useLoyaltyLionCustomerTier,
} from "@/utils/loyaltylion";
import { Leaf2, MenuIcon } from "@cgc/ui/icons";
import { Logo } from "@cgc/ui/logo";
import { Heading, Paragraph } from "@cgc/ui/typography";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { signOut } from "next-auth/react";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useState } from "react";

function titleCase(string: string) {
  return string.replace(/\b\w/g, (char) => char.toUpperCase());
}

function CustomerProfile({ name }: { name: string }) {
  const [customer] = useLoyaltyLionCustomer();
  const [tier] = useLoyaltyLionCustomerTier();

  let tierImageUrl = "bronze.svg"; // TODO: introduce placeholder

  switch (tier?.name.toLowerCase()) {
    case "gold":
      tierImageUrl = "gold.svg";
      break;
    case "silver":
      tierImageUrl = "silver.svg";
      break;
    case "bronze":
      tierImageUrl = "bronze.svg";
      break;
  }

  return (
    <div className="md:justify-center">
      <div className="flex flex-row md:w-fit md:items-center md:justify-items-center">
        <div className="md:relative lg:relative lg:mt-4 xl:absolute xl:left-[14rem] xl:right-0 xl:top-10">
          <div className="relative items-center justify-center">
            <Image
              src={`/assets/tiers/${tierImageUrl}`}
              width={70}
              height={70}
              alt="craft gin club rewards tier"
              className="md:h-1/4 md:w-1/4 lg:h-11 lg:w-11 xl:h-10 xl:w-10"
            />
          </div>
        </div>

        <div className="relative flex flex-col gap-0.5">
          <Heading.h2 className="!text-4xl">{name}</Heading.h2>

          <Link
            href={MARoutes.REWARDS}
            className="flex flex-row gap-4 no-underline"
          >
            <div className="flex min-h-12 flex-col gap-0.5">
              {tier && (
                <Heading.h3 className="!text-lg font-normal ">
                  {titleCase(`${tier.name} level member`)}
                </Heading.h3>
              )}
              {customer && (
                <Paragraph size="p2" className="!text-xs uppercase">
                  {customer.pointsApproved} points
                </Paragraph>
              )}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export const MembersAreaNavigation = ({ session }: { session: any }) => {
  const [isOpen, setIsOpen] = useState(false);
  const pathname = usePathname();

  const primaryLinks = [
    {
      label: "My membership",
      href: "/members-area/subscriptions",
      isActive: pathname === "/members-area/subscriptions",
      icon: "/assets/icons/icon-my-membership.svg",
    },
    {
      label: "Account details",
      href: "/members-area/profile",
      isActive: pathname === "/members-area/profile",
      icon: "/assets/icons/icon-account-details.svg",
    },
    {
      label: "Order History",
      href: "/members-area/orders",
      isActive: pathname === "/members-area/orders",
      icon: "/assets/icons/icon-order-history.svg",
    },
    {
      label: "Refer a Friend",
      href: "/members-area/refer-a-friend",
      isActive: pathname === "/members-area/refer-a-friend",
      icon: "/assets/icons/icon-refer-a-friend.svg",
    },
    {
      label: "Rewards",
      href: "/members-area/rewards",
      isActive: pathname === "/members-area/rewards",
      icon: "/assets/icons/icon-rewards.svg",
    },
  ];

  const secondaryLinks = [
    {
      label: "Gift Guide",
      href: "https://club.craftginclub.co.uk/collections/gift-guide",
      icon: "/assets/icons/icon-gift-guide.svg",
    },
    {
      label: "Gift Memberships",
      href: "/gifts",
      icon: "/assets/icons/icon-gift-memberships.svg",
    },
    {
      label: "Online Shop",
      href: "https://club.craftginclub.co.uk",
      icon: "/assets/icons/icon-online-shop.svg",
    },
    {
      label: "Blog",
      href: "/ginnedmagazine",
      icon: "/assets/icons/icon-ginned-blog.svg",
    },
  ];

  return (
    <>
      <div className="flex items-center gap-4 bg-white px-4 py-4 lg:hidden">
        {/* Menu button */}
        <button
          className="p-2"
          aria-label="Open navigation"
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <MenuIcon className="w-12" />
        </button>

        {/* Points summary */}
        <CustomerProfile name={session.user.name} />
      </div>

      <div
        className={clsx("absolute inset-0 z-40 lg:hidden", {
          block: isOpen,
          hidden: !isOpen,
        })}
        onClick={() => setIsOpen(false)}
      >
        {/* Element used for closing the drawer when open */}
      </div>

      {/* Drawer */}
      <div
        className={clsx(
          "mr-20 h-screen max-h-screen w-[300px] flex-shrink-0 overflow-hidden lg:mr-0",
          "-translate-x-full transition duration-500 ease-in-out lg:translate-x-0",
          "fixed bottom-0 left-0 top-0 z-50 py-3 pr-3 lg:sticky lg:block",
          "text-cgc-grey rounded-r-xl bg-[#D5ECE0] lg:rounded-none",
          {
            "translate-x-0": isOpen,
          },
        )}
      >
        <div className="h-full overflow-hidden">
          <div className="flex justify-end overflow-y-scroll lg:hidden">
            <button
              onClick={() => setIsOpen(false)}
              className="p-2 text-white"
              aria-label="Close navigation"
            >
              <XMarkIcon className="w-8" />
            </button>
          </div>

          <div className="mx-5">
            <CustomerProfile name={session.user.name} />
          </div>

          {/* White separator line */}
          <div className="border-cgc-white-20 m-5 border-t" />

          {/* Menu links */}
          <div className="mr-5 flex flex-col">
            {primaryLinks.map((item, i) => {
              const { label, href, isActive } = item;

              return (
                <Link
                  key={`link_${i}_${href}`}
                  href={href}
                  className={clsx(
                    "text-cgc-grey flex items-center gap-3 rounded-r-xl p-3 pl-5 text-lg uppercase no-underline",
                    isActive && "bg-white",
                  )}
                  onClick={() => setIsOpen(false)}
                >
                  <Image
                    src={item.icon}
                    width={20}
                    height={20}
                    alt="icon"
                    className="w-5"
                  />

                  {/* mt-1 used to offset height difference caused by text being uppercase and not having any underline rendering of characters */}
                  <span className="mt-1 leading-none">{label}</span>
                </Link>
              );
            })}
          </div>

          {/* White separator line */}
          <div className="border-cgc-white-20 m-5 border-t" />

          {/* Secondary links */}
          <div className="mr-5 flex flex-col">
            {secondaryLinks.map((item, i) => {
              const { label, href } = item;

              return (
                <a
                  key={`link_${i}_${href}`}
                  href={href}
                  target="_blank"
                  className="text-cgc-grey flex items-center gap-3 rounded-r-xl p-3 pl-5 text-lg uppercase no-underline"
                  onClick={() => setIsOpen(false)}
                >
                  <Image
                    src={item.icon}
                    width={20}
                    height={20}
                    alt="icon"
                    className="w-5"
                  />

                  {/* mt-1 used to offset height difference caused by text being uppercase and not having any underline rendering of characters */}
                  <span className="mt-1 leading-none">{label}</span>
                </a>
              );
            })}

            {/* Log out button */}
            <button
              className="flex items-center gap-2 rounded-r-xl p-3 pl-5 text-lg uppercase no-underline"
              onClick={() => {
                setIsOpen(false);

                signOut({ callbackUrl: "/" });
              }}
            >
              <XMarkIcon className="w-5" />

              {/* mt-1 used to offset height difference caused by text being uppercase and not having any underline rendering of characters */}
              <span className="mt-1 leading-none">Log out</span>
            </button>
          </div>
        </div>

        {/* Logo accessory at the bottom */}
        <Leaf2
          color={"white"}
          className="absolute -bottom-5 -left-10 hidden w-full rotate-[170deg] fill-white lg:block [@media(max-height:900px)]:hidden"
        />

        <Logo className="absolute bottom-5 left-5 z-50 hidden w-32 lg:block [@media(max-height:900px)]:hidden" />
      </div>
    </>
  );
};
